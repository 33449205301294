import React, { createContext, useContext, ReactNode } from 'react';
import { PublicClientApplication, IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';

interface AuthProviderProps {
    children: ReactNode;
}

const msalConfig = {
    auth: {
        clientId: "ae561054-b99f-4777-b813-f8106cafa96e\n",
        authority: "https://login.microsoftonline.com/7568a010-62d0-41d2-8623-ad5f9389b712",
        redirectUri: "https://grieg.i-shop.no/",
    },
    cache: {
        cacheLocation: "sessionStorage", // This ensures tokens are stored securely for SPAs
        storeAuthStateInCookie: false, // Set to true if experiencing issues on IE11/Edge
    }
};

const pca: IPublicClientApplication = new PublicClientApplication(msalConfig);

const AuthContext = createContext<IPublicClientApplication>(pca);

export const useAuth = () => useContext(AuthContext);


export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    return (
        <MsalProvider instance={pca}>
            <AuthContext.Provider value={pca}>
                {children}
            </AuthContext.Provider>
        </MsalProvider>
    );
};