import React, {useContext, useState} from 'react';
import {Badge, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import {TiShoppingCart} from "react-icons/ti";
import {ShoppingCartContext} from "../Context/ShoppingCartContext";
import {useQuery} from "react-query";
import http from "../Services/Network/http";
import {NO, GB} from "country-flag-icons/react/1x1";
import {useTranslation} from "react-i18next";

export const NavMenu = ({openShoppingCart, hideMenuItems}) => {
  const [navBarCollapsed, setNavBarCollapsed] = useState(true);
  const [t, i18n] = useTranslation('base');
  const toggleNavbar  = ()  => {
    setNavBarCollapsed(!navBarCollapsed);
  }
  const shoppingCartContext = useContext(ShoppingCartContext);

  const { data: pages } = useQuery(
      "fetchPages",
      () => http.get(`/Page`), {enabled:!hideMenuItems});
  
  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" fixed={"top"} light>
        <Container>
          <NavbarBrand tag={Link} to="/" style={{position:"absolute", top:0, padding:0}}><img style={{height: "72px"}} alt="logo" src="/grieg-seafood-logo.png"/></NavbarBrand>
          
              <>
                <NavbarToggler onClick={() => toggleNavbar()} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!navBarCollapsed} navbar>
                  <ul className="navbar-nav flex-grow">
                    {hideMenuItems === false &&
                        <>
                          <NavItem>
                            <NavLink tag={Link} className="text-white" to="/Products">{t('nav.products')}</NavLink>
                          </NavItem>
                          {pages && pages.filter(x=>x.showInMenu).sort(function(a, b) {
                            return parseInt(a.order) - parseFloat(b.order);
                          }).map((item, index) => (
                              <NavItem key={index}>
                                <NavLink tag={Link} className="text-white" to={`/page/${item.pageId}`}>{item.title}</NavLink>
                              </NavItem>
                          ))}
                          <NavItem style={{cursor: 'pointer'}} onClick={() => openShoppingCart()}><TiShoppingCart fontSize={25} style={{margin: 7}} color={"white"} /><Badge color={"info"}>{shoppingCartContext?.state?.reduce((acc, item) => acc + item.quantity, 0)}</Badge></NavItem>
                        </>
                    }
                    <NavItem style={{margin:6}}>
                      <GB title="United States" width={22} style={{cursor:'pointer'}} onClick={() => i18n.changeLanguage("en")}/>
                      &nbsp;
                      <NO title="Norway" width={22} style={{cursor:'pointer'}} onClick={() => i18n.changeLanguage("no")}/>
                    </NavItem>
                  </ul>
                </Collapse>
              </>
        </Container>
      </Navbar>
    </header>
  );
}
