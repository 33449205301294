import React, {useEffect} from "react";
import {QueryClient, QueryClientProvider} from "react-query";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './custom.css'

import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {MainRouter} from "./MainRouter";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import base_en from "./translations/en/base.json";
import base_no from "./translations/no/base.json";
import {AuthProvider} from "./Context/AuthProvider";


const queryClient = new QueryClient();

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
        en: {
            base: base_en               // 'common' is our custom namespace
        },
        no: {
            base: base_no
        },
    },
});

const App = () => {
    useEffect(() => {
        /*const unloadCallback = (event : any) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);*/
    }, []);
    return (
        <AuthProvider>
        <QueryClientProvider client={queryClient}>
            <I18nextProvider i18n={i18next}>
                <MainRouter />
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored" />
            </I18nextProvider>
        </QueryClientProvider>
        </AuthProvider>
    );
  
}


export default App;