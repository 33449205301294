import LocalStorageService from "../../Services/Storage/LocalStorageService";
import {useNavigate} from "react-router";
import {Navigate} from "react-router-dom";
import React from "react";
import {useMsal} from "@azure/msal-react";

export const Logout = () => {
    const navigate = useNavigate();
    const msal = useMsal();
    msal.instance.logoutPopup().then((response) => {
        LocalStorageService.clearToken();
        navigate("/auth/login");
    }).catch(e => {
        console.error(e);
    });
    return <Navigate to="/auth/login" />;
}