import {NavMenu} from "../components/NavMenu";
import {
    Card,
    CardBody, CardSubtitle,
    CardTitle,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    UncontrolledAlert
} from "reactstrap";
import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import http from "../Services/Network/http";
import {Link, Route, Routes} from "react-router-dom";
import {PrivateRoute, Role} from "../Helpers/PrivateRoute";
import {Products} from "../Pages/Products";
import {Credit} from "../Pages/Account/Credit";
import {Orders} from "../Pages/Account/Orders";
import {Edit} from "../Pages/Account/Edit";
import {Logout} from "../Pages/Account/Logout";
import {AdminProductList} from "../Pages/Admin/Product/ProductList";
import {AdminProduct} from "../Pages/Admin/Product/Product";
import {AdminProductCategoriesList} from "../Pages/Admin/ProductCategory/ProductCategoriesList";
import {AdminProductCategory} from "../Pages/Admin/ProductCategory/ProductCategory";
import {AppRoutesRouter} from "../app-routes";
import {ShoppingCartContextProvider} from "../Context/ShoppingCartContext";
import ShoppingCartDrawer from "../components/ShoppingCartDrawer";
import {useLocation} from "react-router";
import {Checkout} from "../Pages/Checkout";
import {AdminSupplierList} from "../Pages/Admin/Supplier/SupplierList";
import {AdminSupplier} from "../Pages/Admin/Supplier/Supplier";
import {AdminUserList} from "../Pages/Admin/User/UserList";
import {AdminUser} from "../Pages/Admin/User/User";
import {Order} from "../Pages/Account/Order";
import {AdminUserCreditList} from "../Pages/Admin/User/CreditList";
import {AdminPageList} from "../Pages/Admin/Page/PageList";
import {AdminPage} from "../Pages/Admin/Page/Page";
import {Page} from "../Pages/Page";
import {AdminDocumentList} from "../Pages/Admin/Document/DocumentList";
import {AdminDocument} from "../Pages/Admin/Document/Document";
import {AdminProductVariantList} from "../Pages/Admin/Product/ProductVariantList";
import {AdminProductVariant} from "../Pages/Admin/Product/ProductVariant";
import config from "../config";
import {AdminSystemMessagesList} from "../Pages/Admin/System/MessagesList";
import {AdminSystemMessage} from "../Pages/Admin/System/Message";
import {Product} from "../Pages/product";
import localStorageService from "../Services/Storage/LocalStorageService";
import {SupplierOrders} from "../Pages/Supplier/Orders";
import {SupplierOrder} from "../Pages/Supplier/Order";
import {AdminOrder} from "../Pages/Admin/Order/Order";
import {AdminOrders} from "../Pages/Admin/Order/Orders";
import {AdminCostAccount} from "../Pages/Admin/CostAccount/CostAccount";
import {AdminCostAccountList} from "../Pages/Admin/CostAccount/CostAccountList";
import {useTranslation} from "react-i18next";
import {OrderApprovalList} from "../Pages/Account/Leader/OrderApprovalList";
import {OrderApproval} from "../Pages/Account/Leader/OrderApproval";
import {SupplierProducts} from "../Pages/Supplier/SupplierProducts";
import {AdminUserAddUpdateCredit} from "../Pages/Admin/User/AddUpdateCredit";
import {LeaderUserList} from "../Pages/Account/Leader/UserList";
import {BulkAddCredit} from "../Pages/Account/Leader/BulkAddCredit";
import {LeaderUser} from "../Pages/Account/Leader/User";
import {LeaderUserAddUpdateCredit} from "../Pages/Account/Leader/AddUpdateCredit";
import {LeaderUserCreditList} from "../Pages/Account/Leader/CreditList";
import {AdminBulkAddCredit} from "../Pages/Admin/User/BulkAddCredit";
import {AdminWelcomeMail} from "../Pages/Admin/MailTemplates/WelcomeMail";
import {axiosInstance} from "../Services/Network/axios";
const fileDownload = require('js-file-download');

const SidebarMenusGenerator = ({sidebarMenuItems, loadCategories} : any) => {
    const userRole = localStorageService.getAccessRole();
    
    const [menuItems, setMenuItems] = useState(sidebarMenuItems);

    const [t] = useTranslation('base');
    
    const { data: productCategories } = useQuery(
        "fetchProductCategories",
        () => http.get("/Product/GetProductCategories?filtered=true"), {enabled: loadCategories})

    const { data: documents } = useQuery(
        "fetchDocuments",
        () => http.get("/Document"), {enabled: loadCategories})

    const { data: user } = useQuery(
        "fetchAccount",
        () => http.get(`/Account`));
    
    useEffect(() => {
        if(user?.responsibleForCostAccounts.length === 0) {
            setMenuItems(menuItems.filter((x : any) => x.title !== 'Leder'))
        }
    }, [user]);
    
    useEffect(() => {
        const mainItems = [...sidebarMenuItems]
        if(loadCategories) {
            mainItems.filter((x) => x.title == t('nav.products'))[0].items = productCategories?.map((item: any) => ({
                link: `/products/${item.productCategoryId}`,
                title: item.title,
                target: '_self',
                roles: ['Normal','Admin','Supplier'],
            }));
            mainItems.filter((x) => x.title == t('nav.documents'))[0].items = documents?.filter((item:any)=>item.showInMenu).map((item: any) => ({
                link: `${config.api.url}file/${item.documentFile.viewToken}`,
                title: item.title,
                target: '_blank',
                roles: ['Normal','Admin','Supplier'],
            }));
        }

        if(user?.responsibleForCostAccounts.length === 0) {
            setMenuItems(menuItems.filter((x : any) => x.title !== 'Leder'))
        }
        
        setMenuItems(mainItems)
    }, [productCategories, documents, sidebarMenuItems, loadCategories]);

    
    return <div>
        {menuItems?.filter((x: any) => (x.title !== 'Leder' && x.title !== 'Manager' && user?.responsibleForCostAccounts.length === 0) || user?.responsibleForCostAccounts.length !== 0).map((item:any, index:any) => ((item.roles).indexOf(userRole) > -1) && (
            <Row key={index} style={{marginBottom: 10}}>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <CardTitle tag="h5"><b>{item.title}</b></CardTitle>
                            <Nav vertical>
                                {item?.items?.map((navItem:any, index2:any) => ((navItem.roles).indexOf(userRole) > -1) && (
                                    <NavItem key={index2}>
                                        <NavLink tag={Link} to={navItem.onClick === undefined ? navItem.link : "#"} target={navItem.onClick === null ? navItem.target : navItem.target === "_blank" ? "_blank" : "_self"} onClick={navItem.onClick}>{navItem.title}</NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
    ))}
    </div>
}

export const MainLayout = () => {

    const [t] = useTranslation('base');
    
    const sidebarMenuItems = [
        {
            title: t('nav.account'),
            roles: ['Normal','Admin','Supplier'],
            items: [
                {
                    link: "/account/credit",
                    title: t('nav.myCredit'),
                    roles: ['Normal','Admin','Supplier'],
                },
                {
                    link: "/account/orders",
                    title: t('nav.purchaseHistory'),
                    roles: ['Normal','Admin','Supplier'],
                },
                {
                    link: "/account/edit",
                    title: t('nav.changeAccountInformation'),
                    roles: ['Normal','Admin','Supplier'],
                },
                {
                    link: "/admin",
                    title: t('nav.admin'),
                    roles: ['Admin'],
                },
                {
                    link: "/account/logout",
                    title: t('nav.logout'),
                    roles: ['Normal','Admin','Supplier'],
                }
            ]
        },
        {
            title: "Leder",
            roles: ['Normal','Admin','Supplier'],
            items: [
                {
                    link: "/account/leader/orderApproval",
                    title: t('nav.orderApproval'),
                    roles: ['Normal','Admin','Supplier'],
                },
                {
                    link: "/account/leader/users",
                    title: "Brukere",
                    roles: ['Normal','Admin','Supplier'],
                },
                {
                    link: "/account/leader/bulkAddCredit",
                    title: "Legg til poeng i bulk",
                    roles: ['Normal','Admin','Supplier'],
                },
            ]
        },
        {
            title: t('nav.products'),
            roles: ['Normal','Admin','Supplier'],
            items: [
                {
                    link: "/products",
                    title: t('nav.allProducts'),
                    roles: ['Normal','Admin','Supplier'],
                }
            ]
        },
        {
            title: t('nav.documents'),
            items: [],
            roles: ['Normal','Admin','Supplier'],
        }
    ];

    const sidebarAdminMenuItems = [
        {
            title: t('nav.admin'),
            roles: ['Admin'],
            items: [
                {
                    link: "/admin/products",
                    title: t('nav.products'),
                    roles: ['Admin'],
                },
                {
                    link: "/admin/ProductCategoriesList",
                    title: t('nav.productCategories'),
                    roles: ['Admin'],
                },
                {
                    link: "/admin/SupplierList",
                    title: t('nav.suppliers'),
                    roles: ['Admin'],
                },
                {
                    link: "/admin/orders",
                    title: t('nav.orderManagement'),
                    roles: ['Admin'],
                },
                {
                    link: "/admin/pages",
                    title: t('nav.pages'),
                    roles: ['Admin'],
                },
                {
                    link: "/admin/documents",
                    title: t('nav.documents'),
                    roles: ['Admin'],
                },
                {
                    link: "/admin/system/messages",
                    title: t('nav.systemMessages'),
                    roles: ['Admin'],
                },
                {
                    link: "/admin/costAccounts",
                    title: t('nav.costAccounts'),
                    roles: ['Admin'],
                },
                {
                    link: "/admin/users",
                    title: t('nav.users'),
                    roles: ['Admin'],
                },
                {
                    link: "/admin/users/bulkAddCredit",
                    title: "Legg til poeng i bulk",
                    roles: ['Admin'],
                },
                {
                    link: config.api.url + "report/GetAllUserCredits",
                    title: "Rapport poengbruk",
                    roles: ['Admin'],
                    onClick: () => axiosInstance.get(config.api.url + "report/GetAllUserCredits", {responseType: 'blob'}).then((response) => { fileDownload(response.data, 'Rapport poengbruk.xlsx'); })
                },
                {
                    link: config.api.url + "report/GetSoldProducts",
                    title: "Rapport produktbruk",
                    roles: ['Admin'],
                    onClick: () => axiosInstance.get(config.api.url + "report/GetSoldProducts", {responseType: 'blob'}).then((response) => { fileDownload(response.data, 'Rapport produktbruk.xlsx'); })
                },
                {
                    link: "/admin/mailTemplates/welcomeMail",
                    title: "Velkomstepost",
                    roles: ['Admin'],
                },
                
            ]
        }
    ]
    
    const [shoppingCartIsOpen, setShoppingCartIsOpen] = useState(false);
    const location = useLocation();

    const userRole = localStorageService.getAccessRole();
    
    const {data: messages} = useQuery(
        ["fetchSystemMessages"],
        () => http.get(`/System/GetMessages`), {enabled: userRole !== "Supplier"})

    const { data: user } = useQuery(
        "fetchAccount",
        () => http.get(`/Account`));
    
    return userRole === "Supplier" ? (
        <div>
            <NavMenu hideMenuItems={true} openShoppingCart={() => setShoppingCartIsOpen(true)} />
            <Container style={{marginTop: 90}}>
                <Row>
                    <Col md={3}>
                        <Row style={{marginBottom: 10}}>
                            <Col md={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle tag="h5"><b>Leverandør</b></CardTitle>
                                        <Nav vertical>
                                            <NavItem>
                                                <NavLink tag={Link} to="/orders" target="_self">Ordrer</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/products" target="_self">Produkter</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/account/logout" target="_self">Logg ut</NavLink>
                                            </NavItem>
                                        </Nav>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={9}>
                        <Routes>
                            <Route path='/' element={<PrivateRoute component={SupplierOrders} />} />
                            <Route path='/products' element={<PrivateRoute component={SupplierProducts} />} />
                            <Route path='/orders' element={<PrivateRoute component={SupplierOrders} />} />
                            <Route path='/order/:id' element={<PrivateRoute component={SupplierOrder} />} />
                            <Route path='/account/logout' element={<PrivateRoute component={Logout} />} />
                        </Routes>
                    </Col>
                </Row>
            </Container>
        </div>
    ) : (
        <ShoppingCartContextProvider>
            <div>
                <NavMenu hideMenuItems={false} openShoppingCart={() => setShoppingCartIsOpen(true)} />
                <Container style={{marginTop: 90}}>
                    {(messages || []).map((item : any, index : number) => (
                        <UncontrolledAlert key={index} color={item.type === 1 ? "info" : item.type === 2 ? "warning" : "danger"}>
                            <h4 className="alert-heading">{item.title}</h4>
                            <p>
                                {item.message}
                            </p>
                        </UncontrolledAlert >
                    ))}
                    <ShoppingCartDrawer isOpen={shoppingCartIsOpen} closeDrawer={setShoppingCartIsOpen} />
                    <Row>
                        <Col md={3}>
                            <Row style={{marginBottom: 10}}>
                                <Col md={12}>
                                    <Card>
                                        <CardBody>
                                            <b>Logget inn som:</b><br/>
                                            {user?.fullName}<br/>
                                            <Link to={"/account/logout"}>{t('nav.logout')}</Link><br/><br/>
                                            <b>Poeng:</b><br/>
                                            {user?.remainingCredit} <br/> <br/>
                                            
                                            <b>Tilhørighet:</b><br/>
                                            {user?.costAccount?.costAccountNumber} - {user?.costAccount?.costAccountName}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <SidebarMenusGenerator loadCategories={!location.pathname.startsWith("/admin")} sidebarMenuItems={location.pathname.startsWith("/admin") ? sidebarAdminMenuItems : sidebarMenuItems } />
                        </Col>
                        <Col md={9}>
                            
                            <Routes>
                                <Route path='/checkout' element={<PrivateRoute component={Checkout} />} />
                                <Route path='/account/credit' element={<PrivateRoute component={Credit} />} />
                                <Route path='/account/orders' element={<PrivateRoute component={Orders} />} />
                                <Route path='/account/leader/orderApproval' element={<PrivateRoute component={OrderApprovalList} />} />
                                <Route path='/account/leader/orderApproval/:id' element={<PrivateRoute component={OrderApproval} />} />
                                <Route path='/account/leader/users' element={<PrivateRoute component={LeaderUserList} />} />
                                <Route path='/account/leader/user' element={<PrivateRoute component={LeaderUser} />} />
                                <Route path='/account/leader/user/:id' element={<PrivateRoute component={LeaderUser} />} />
                                <Route path='/account/leader/user/credit/:userId' element={<PrivateRoute component={LeaderUserCreditList} />} />
                                <Route path='/account/leader/user/credit/:userId/add' element={<PrivateRoute component={LeaderUserAddUpdateCredit} />} />
                                <Route path='/account/leader/user/credit/:userId/update/:periodYear' element={<PrivateRoute component={LeaderUserAddUpdateCredit} />} />
                                <Route path='/account/leader/bulkAddCredit' element={<PrivateRoute component={BulkAddCredit} />} />
                                <Route path='/account/orders/:period' element={<PrivateRoute component={Orders} />} />
                                <Route path='/account/order/:id' element={<PrivateRoute component={Order} />} />
                                <Route path='/account/edit' element={<PrivateRoute component={Edit} />} />
                                <Route path='/account/logout' element={<PrivateRoute component={Logout} />} />
                                <Route path='/admin' element={<PrivateRoute component={AdminProductList} roles={[Role.Admin]} />} />
                                <Route path='/admin/products' element={<PrivateRoute component={AdminProductList} roles={[Role.Admin]} />} />
                                <Route path='/admin/product' element={<PrivateRoute component={AdminProduct} roles={[Role.Admin]} />} />
                                <Route path='/admin/product/:id' element={<PrivateRoute component={AdminProduct} roles={[Role.Admin]} />} />
                                <Route path='/admin/product/:id/productVariants' element={<PrivateRoute component={AdminProductVariantList} roles={[Role.Admin]} />} />
                                <Route path='/admin/product/:id/productVariant' element={<PrivateRoute component={AdminProductVariant} roles={[Role.Admin]} />} />
                                <Route path='/admin/product/:id/productVariant/:productVariantId' element={<PrivateRoute component={AdminProductVariant} roles={[Role.Admin]} />} />
                                <Route path='/admin/productCategoriesList' element={<PrivateRoute component={AdminProductCategoriesList} roles={[Role.Admin]} />} />
                                <Route path='/admin/productCategory' element={<PrivateRoute component={AdminProductCategory} roles={[Role.Admin]} />} />
                                <Route path='/admin/productCategory/:id' element={<PrivateRoute component={AdminProductCategory} roles={[Role.Admin]} />} />
                                <Route path='/admin/supplierList' element={<PrivateRoute component={AdminSupplierList} roles={[Role.Admin]} />} />
                                <Route path='/admin/supplier' element={<PrivateRoute component={AdminSupplier} roles={[Role.Admin]} />} />
                                <Route path='/admin/supplier/:id' element={<PrivateRoute component={AdminSupplier} roles={[Role.Admin]} />} />
                                <Route path='/admin/costAccounts' element={<PrivateRoute component={AdminCostAccountList} roles={[Role.Admin]} />} />
                                <Route path='/admin/costAccount' element={<PrivateRoute component={AdminCostAccount} roles={[Role.Admin]} />} />
                                <Route path='/admin/costAccount/:id' element={<PrivateRoute component={AdminCostAccount} roles={[Role.Admin]} />} />
                                <Route path='/admin/users' element={<PrivateRoute component={AdminUserList} roles={[Role.Admin]} />} />
                                <Route path='/admin/users/bulkAddCredit' element={<PrivateRoute component={AdminBulkAddCredit} roles={[Role.Admin]} />} />
                                <Route path='/admin/user' element={<PrivateRoute component={AdminUser} roles={[Role.Admin]} />} />
                                <Route path='/admin/user/:id' element={<PrivateRoute component={AdminUser} roles={[Role.Admin]} />} />
                                <Route path='/admin/user/credit/:userId' element={<PrivateRoute component={AdminUserCreditList} roles={[Role.Admin]} />} />
                                <Route path='/admin/user/credit/:userId/add' element={<PrivateRoute component={AdminUserAddUpdateCredit} roles={[Role.Admin]} />} />
                                <Route path='/admin/user/credit/:userId/update/:periodYear' element={<PrivateRoute component={AdminUserAddUpdateCredit} roles={[Role.Admin]} />} />
                                <Route path='/admin/pages' element={<PrivateRoute component={AdminPageList} roles={[Role.Admin]} />} />
                                <Route path='/admin/page' element={<PrivateRoute component={AdminPage} roles={[Role.Admin]} />} />
                                <Route path='/admin/page/:id' element={<PrivateRoute component={AdminPage} roles={[Role.Admin]} />} />
                                <Route path='/admin/documents' element={<PrivateRoute component={AdminDocumentList} roles={[Role.Admin]} />} />
                                <Route path='/admin/document' element={<PrivateRoute component={AdminDocument} roles={[Role.Admin]} />} />
                                <Route path='/admin/document/:id' element={<PrivateRoute component={AdminDocument} roles={[Role.Admin]} />} />
                                <Route path='/admin/system/messages' element={<PrivateRoute component={AdminSystemMessagesList} roles={[Role.Admin]} />} />
                                <Route path='/admin/system/message' element={<PrivateRoute component={AdminSystemMessage} roles={[Role.Admin]} />} />
                                <Route path='/admin/system/message/:id' element={<PrivateRoute component={AdminSystemMessage} roles={[Role.Admin]} />} />
                                <Route path='/admin/mailTemplates/welcomeMail' element={<PrivateRoute component={AdminWelcomeMail} roles={[Role.Admin]} />} />
                                <Route path='/admin/document' element={<PrivateRoute component={AdminDocument} roles={[Role.Admin]} />} />
                                <Route path='/admin/orders' element={<PrivateRoute component={AdminOrders} />} />
                                <Route path='/admin/order/:id' element={<PrivateRoute component={AdminOrder} />} />
                                <Route path='/page/:id' element={<PrivateRoute component={Page} />} />
                                <Route path='/products' element={<PrivateRoute component={Products} />} />
                                <Route path='/product/:id' element={<PrivateRoute component={Product} />} />
                                <Route path={AppRoutesRouter.ProductsByCategoryId} element={<PrivateRoute component={Products} />} />
                                <Route path={AppRoutesRouter.Home} element={<PrivateRoute component={Products} /> } />
                            </Routes>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ShoppingCartContextProvider>
    );
}