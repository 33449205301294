import {useNavigate} from "react-router";
import {useMutation, useQuery, useQueryClient} from "react-query";
import http from "../../../Services/Network/http";
import {BsFillTrashFill,BsBootstrapReboot, BsCashCoin} from "react-icons/bs";
import {Button} from "reactstrap";
import DataTable from "react-data-table-component";
import {toast} from "react-toastify";

export const AdminUserList = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const deleteMutation = useMutation(userId => http.remove(`/User/${userId}`), {
        onSuccess: () => {
            queryClient.invalidateQueries(["fetchUsers"]);
        },
        onError: (error : any) => {
            console.log(error.response.data);
        },
    });
    const sendWelcomeMessageMutation = useMutation(userId => http.get(`/User/ResetPasswordAndSendWelcomeMessage/${userId}`), {
        onSuccess: () => {
            toast("En velkomstmelding har nå blitt sendt til brukeren.", { type: "success" });
        },
        onError: (error : any) => {
            console.log(error.response.data);
        },
    });
    const { data: users } = useQuery(
        "fetchUsers",
        () => http.get(`/User`));

    const columns = [
        {
            name: 'Brukernavn',
            selector: (row : any) => row.username,
            grow: 3,
            sortable: true
        },
        {
            name: 'Etternavn',
            selector: (row : any) => row.lastName,
            grow: 3,
            sortable: true
        },
        {
            name: 'Fornavn',
            selector: (row : any) => row.firstName,
            grow: 3,
            sortable: true
        },
        {
            name: 'Telefon',
            selector: (row : any) => row.cellPhone,
            grow: 3
        },
        {
            name: 'Kostnadssted',
            selector: (row : any) => row.costAccount?.costAccountName,
            grow: 3
        },
        {

            cell: (row : any) => (
                <>
                    <BsCashCoin cursor="Pointer" onClick={() => navigate(`/admin/user/credit/${row.userId}`)} />
                    &nbsp;&nbsp;&nbsp;
                    <BsBootstrapReboot cursor="Pointer" onClick={() => window.confirm("Er du sikker på at du vil sende ny velkomstmelding?") ? sendWelcomeMessageMutation.mutate(row.userId) : ""} />
                    &nbsp;&nbsp;&nbsp;
                    <BsFillTrashFill cursor="Pointer" onClick={() => window.confirm("Er du sikker på at du vil slette brukeren?") ? deleteMutation.mutate(row.userId) : ""} />
                </>),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];


    return (
        <div>
            <h2 style={{float:'left'}}>Admin &gt; Brukere</h2>
            <Button color="primary"  style={{float:'right', display:'none'}} size="sm" onClick={() => navigate("/admin/user")}>Legg til ny bruker</Button>
            <DataTable
                highlightOnHover={true}
                onRowClicked={(row) => navigate(`/admin/user/${row.userId}`)}
                columns={columns}
                data={(users || [])}
            />
        </div>
    );
}