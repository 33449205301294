import axios from "axios";
import LocalStorageService  from "../Storage/LocalStorageService";
import {toast} from "react-toastify";
import config from "../../config";
import {useMsal} from "@azure/msal-react";

// Create axios instance
let baseUrl = config.api.url;

const axiosInstance = axios.create({
    baseURL: baseUrl
});

axiosInstance.interceptors.request.use(
    config => {
        const accessToken = LocalStorageService.getAccessToken();
        if (accessToken) {
            config.headers['Authorization'] = 'Bearer ' + accessToken;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (401 === error?.response?.status) {
            LocalStorageService.clearToken();
            window.location = '/auth/login';

        } if (403 === error?.response?.status) {

            toast("Unauthorized! Attempt has been logged.", { type: "error" });
        }
        else {
            toast(error?.response?.data, { type: "error" });
        }
        return Promise.reject(error);
    }
);
export { axiosInstance };