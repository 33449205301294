import {useQuery} from "react-query";
import http from "../../Services/Network/http";
import moment from "moment";
import DataTable from "react-data-table-component";
import {useParams} from "react-router-dom";

export const Order = () => {
    const { id } = useParams();

    const { data: order } = useQuery(
        ["fetchOrders", id],
        () => http.get(`/Account/Order/${id}`));

    const columns = [
        {
            name: 'Vare.Nr',
            selector: (row : any) => row.productNr,
            grow: 3
        },
        {
            name: 'Tittel',
            selector: (row : any) => row.title,
            grow: 5
        },
        {
            name: 'Variant',
            selector: (row : any) => row.variant,
            grow: 3
        },
        {
            name: 'Pris',
            selector: (row : any) => row.price,
            grow: 3
        },
        {
            name: 'Antall',
            selector: (row : any) => row.quantity,
            grow: 3
        }
    ];


    return (
        <div>
            <h2>Ordrehistorikk &gt; Ordre</h2>
            <hr/>
            <p>
                <b>OrdreId:</b><br/>
                {order?.orderId}
            </p>
            <p>
                <b>Leverandør:</b><br/>
                {order?.supplier?.name}
            </p>
            <p>
                <b>Ordre total:</b><br/>
                {order?.orderItems.reduce((total : number, {price, quantity} : any) => {
                    return total + price * quantity;
                }, 0)}
            </p>
            <p>
                <b>Ordre status:</b><br/>
                {order?.orderStatus?.title}
            </p>
            <p>
                <b>Dato:</b><br/>
                {moment(order?.createdAt).format("DD.MM.yyyy HH:mm")}
            </p>
            <p>
                <b>Notat:</b><br/>
                {order?.notes === "" ? "--" : order?.notes}
            </p>
            <p>
                <b>Notat leverandør:</b><br/>
                {order?.supplierNotes === null ? "--" : order?.supplierNotes}
            </p>
            <hr/>
            <DataTable
                highlightOnHover={true}
                columns={columns}
                data={(order?.orderItems || [])}
            />
        </div>
    );
}