import * as Yup from "yup";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Formik} from "formik";
import {useMutation, useQuery, useQueryClient} from "react-query";
import http from "../../../Services/Network/http";

import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

import { FilePond, registerPlugin } from 'react-filepond'

import 'filepond/dist/filepond.min.css'

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import React, {useEffect, useState} from "react";
import config from "../../../config";
import LocalStorageService from "../../../Services/Storage/LocalStorageService";
import axios from "axios";
import {useParams} from "react-router-dom";
import {Html4Entities as htmlEntities} from "html-entities";
import {useNavigate} from "react-router";

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {useTranslation} from "react-i18next";


const getNodeIds = (nodes) => {
    let ids = [];

    nodes?.forEach(({ value, children }) => {
        ids = [...ids, value, ...getNodeIds(children)];
    });

    return ids;
};

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType)

export const AdminProduct = () => {
    const { id } = useParams();
    const [ expanded, setExpanded ] = useState([]);
    const [ checked, setChecked ] = useState([]);
    
    const navigate = useNavigate();
    const queryClient = useQueryClient()
    
    const productMutation = useMutation(productForSaving => productForSaving.productId === null ? http.post('/product/AddProduct', productForSaving) : http.put('/product/UpdateProduct', productForSaving), {
        onSuccess: (data) => {
            queryClient.invalidateQueries('fetchProduct');
            navigate("/Admin/Products");
        },
        onError: (error, variables, context) => {
            console.log(error.response.data);
        },
    });
    
    const { data: suppliers } = useQuery(
        ["fetchSuppliers"],
        () => http.get(`/Supplier`))

    const { data: productCategories } = useQuery(
        ["fetchProductCategoriesAll"],
        () => http.get(`/Product/GetProductCategories?filtered=false`))

    const { data: product } = useQuery(
        ["fetchProductUnfiltered", id],
        () => http.get(`/Product/GetProduct/${id}?filtered=false`),
        {enabled: id !== undefined})

    const { data: costAccounts } = useQuery(
        "fetchCostAccountsAll",
        () => http.get(`/CostAccount?filtered=false`));
    
    
    const [files, setFiles] = useState([])
    const [removedFiles, setRemovedFiles] = useState([])
    
    useEffect(() => {
        if(product?.productImages !== null) {
            product?.productImages?.forEach((item) => {
                setFiles(files => [...files, {
                    source: item.file?.viewToken,
                    options: {
                        type: "local"
                    }}])
            })
        }
        if(product?.productAccessLinks !== null) {
            product?.productAccessLinks?.forEach((item) => {
                setChecked(nChecked => [...nChecked,
                    item.costAccountId
                ])
            })
        }
    }, [product])
    
    const costAccountData = (costAccounts || []).sort((a, b) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).filter(function (item) {
        return item.parentCostAccountId == null
    }).map((item) => {
            return {
                value: item.costAccountId,
                label: `${item.costAccountNumber} - ${item.costAccountName}`,
                children: (item.costAccounts || []).length === 0 ? null : (item.costAccounts || []).sort((a, b) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).map((item2) => {
                    return {
                        value: item2.costAccountId,
                        label: `${item2.costAccountNumber} - ${item2.costAccountName}`,
                        children: (item2.costAccounts || []).length === 0 ? null : (item2.costAccounts || []).sort((a, b) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).map((item3) => {
                            return {
                                value: item3.costAccountId,
                                label: `${item3.costAccountNumber} - ${item3.costAccountName}`,
                                children: (item3.costAccounts || []).length === 0 ? null : (item3.costAccounts || []).sort((a, b) => a.costAccountNumber > b.costAccountNumber ? 1 : -1).map((item4) => {
                                    return {
                                        value: item4.costAccountId,
                                        label: `${item4.costAccountNumber} - ${item4.costAccountName}`,
                                    }
                                })
                            }
                        })
                    }
                })
            }
        }
    );
    
    useEffect(() => {
        setExpanded(getNodeIds(costAccountData));
    }, [costAccounts]);
    
    const [t] = useTranslation('base');
    
    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    productId: product?.productId ?? null,
                    productNr: product?.productNr ?? "",
                    gender: product?.gender ?? 2,
                    supplierId: product?.supplierId ?? (suppliers || [])[0]?.supplierId ?? 0,
                    productCategoryId: product?.productCategoryId ?? (productCategories || [])[0]?.productCategoryId ?? 0,
                    title: product?.title ?? "",
                    shortDescription: product?.shortDescription ?? "",
                    description: product?.description ?? "",
                    price: product?.price ?? "",
                    active: product?.active ?? true,
                    approvalRequired: product?.approvalRequired ?? false
                }}
                validationSchema={Yup.object({
                    productNr: Yup.string(),
                    supplierId: Yup.number().required(),
                    gender: Yup.number().required(),
                    productCategoryId: Yup.number().required(),
                    title: Yup.string().required(),
                    shortDescription: Yup.string().required(),
                    description: Yup.string(),
                    price: Yup.number().required(),
                    active: Yup.bool().required(),
                    approvalRequired: Yup.bool().required(),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    console.log(values);
                    productMutation.mutate({
                        productId: values.productId,
                        productNr: values.productNr,
                        supplierId: values.supplierId,
                        productCategoryId: values.productCategoryId,
                        title: values.title,
                        gender: values.gender,
                        shortDescription: values.shortDescription,
                        description: values.description,
                        price: values.price,
                        active: values.active,
                        approvalRequired: values.approvalRequired,
                        removedFiles: removedFiles,
                        tempFiles: files.map((item) => item.serverId),
                        costAccountIdsWithAccess: checked
                    });
                }}>
                {
                    ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         isSubmitting,
                         isValid,
                         handleSubmit,
                         setFieldValue
                     }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Row>
                                <Col md={8}>
                                    <FormGroup>
                                        <Label for="title">
                                            {t('product.title')}
                                        </Label>
                                        <Input
                                            id="title"
                                            name="title"
                                            placeholder={t('product.title')}
                                            invalid={touched.title && !!errors.title}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.title}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="productCategoryId">
                                            {t('product.category')}
                                        </Label>
                                        <Input
                                            type="select"
                                            name="productCategoryId"
                                            id="productCategoryId"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.productCategoryId}>
                                            {(productCategories || []).map((item, index) => (<option key={index} value={item.productCategoryId}>{item.title}</option>))}

                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="productNr">
                                            Produkt.Nr
                                        </Label>
                                        <Input
                                            id="productNr"
                                            name="productNr"
                                            placeholder="Produkt.Nr"
                                            invalid={touched.productNr && !!errors.productNr}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.productNr}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="price">
                                            Pris
                                        </Label>
                                        <Input
                                            id="price"
                                            name="price"
                                            placeholder="Pris"
                                            invalid={touched.price && !!errors.price}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.price}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="gender">
                                            {t('product.gender')}
                                        </Label>
                                        <Input
                                            type="select"
                                            name="gender"
                                            id="gender"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.gender}>
                                            {([{id: 0, gender: t('product.gender-male')},{id: 1, gender: t('product.gender-female')},{id: 2, gender: t('product.gender-unisex')}]).map((item, index) => (<option key={index} value={item.id}>{item.gender}</option>))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="supplierId">Leverandør</Label>
                                        <Input
                                            type="select"
                                            name="supplierId"
                                            id="supplierId"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.supplierId}>
                                            {(suppliers || []).map((item, index) => (<option key={index} value={item.supplierId}>{item.name}</option>))}
                                            
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="shortDescription">
                                            Kort beskrivelse
                                        </Label>
                                        <Input
                                            type="textarea"
                                            id="shortDescription"
                                            name="shortDescription"
                                            placeholder="Kort beskrivelse"
                                            invalid={touched.shortDescription && !!errors.shortDescription}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.shortDescription}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="description">
                                            Beskrivelse
                                        </Label>
                                        <ReactQuill 
                                            theme="snow"
                                            id="description"
                                            name="description"
                                            value={htmlEntities.decode(values.description)}
                                            onChange={(e) => setFieldValue('description', htmlEntities.encode(e))}
                                            modules={{
                                                clipboard: {
                                                    matchVisual: true
                                                }
                                            }}
                                        />
                                        
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup check>
                                        <Label check for="active">
                                            <Input
                                                name="active"
                                                id="active"
                                                type="checkbox"
                                                invalid={touched.active && !!errors.active}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.active}
                                            />
                                            Aktiv?
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup check>
                                        <Label check for="approvalRequired">
                                            <Input
                                                name="approvalRequired"
                                                id="approvalRequired"
                                                type="checkbox"
                                                invalid={touched.approvalRequired && !!errors.approvalRequired}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.approvalRequired}
                                            />
                                            Må godkjennes?
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="description">
                                            Vises kunn for?
                                        </Label>
                                        <CheckboxTree
                                            nodes={costAccountData}
                                            checked={checked}
                                            onlyLeafCheckboxes={true}
                                            showExpandAll={true}
                                            searchable={true}
                                            expanded={expanded}
                                            onCheck={nChecked =>  setChecked(nChecked)}
                                            onExpand={nExpanded => setExpanded(nExpanded)}
                                            iconsClass="fa5"
                                            
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col md={12}>
                                    <FilePond
                                        files={files}
                                        onupdatefiles={setFiles}
                                        allowMultiple={true}
                                        maxFiles={3}
                                        server={
                                        {
                                            url: config.api.url,
                                            headers: {'Authorization': `Bearer ${LocalStorageService.getAccessToken()}`, 'Access-Control-Allow-Origin': '*'},
                                            process: {
                                                url: 'file/Upload',
                                                method: 'POST',
                                                withCredentials: false,
                                                timeout: 7000,
                                            },
                                            load: 'file/',
                                            revert: null,
                                            remove: (source, load, error) => {
                                                if(removedFiles === null) {
                                                    const authorization = {'Authorization': `Bearer ${LocalStorageService.getAccessToken()}`};
                                                    const header = {
                                                        "Content-Type": "application/json",
                                                        'Access-Control-Allow-Origin': '*'
                                                    };
                                                    Object.assign(header, authorization);
                                                    const requestOptions = {
                                                        headers: header
                                                    };

                                                    axios.delete(`${config.api.url}file/${source}`, requestOptions).then(() => {
                                                        load();
                                                    }).catch(() => {
                                                        error("Noe gikk galt under sletting av fil.");
                                                    });
                                                } else {
                                                    setRemovedFiles([...removedFiles, source]);
                                                    load();
                                                }
                                            },
                                            imagePreviewMaxHeight: 80,
                                        }}
                                        name="filepond"
                                        acceptedFileTypes={['image/*']}
                                        labelIdle='Dra & slipp bildene dine eller <span class="filepond--label-action">Bla gjennom</span>'
                                    />
                                </Col>
                            </Row>
                            
                            <br/>
                            <Button type="submit" disabled={isSubmitting || !isValid}>
                                {isSubmitting ? 'Laster...' : 'Lagre'}
                            </Button>
                            <br/>
                            <br/>
                        </Form>
                    )}
            </Formik>
        </div>
    )
}