const dev = {
    api: {
        url: "//localhost:7163/api/"
    }
};
const prod = {
    api: {
        url: "/api/"
    }
};
const config = process.env.REACT_APP_STAGE === 'production'
    ? prod
    : dev;
export default config;